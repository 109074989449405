@import url(https://fonts.googleapis.com/css?family=Roboto:500,400italic,300,500italic,300italic,400);

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

p {
    color: #8e8e8e;
}
